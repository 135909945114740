import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LoadingSpinner } from "../elements/Form";
import { BoxedContentLayout } from "../elements/Layout";
import { ListItem } from "../elements/ListItem";
import { COLORS } from "../elements/Tile";
import { useCurrentUser } from "../states/CurrentUserState";
import { useLists } from "../states/ListsState";
import { useAuthHeaders } from "../states/LoginState";

export const ListsOverview = () => {
  const authHeaders = useAuthHeaders();
  const currentUser = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [lists, setLists] = useLists();

  const fetchData = useCallback((refreshing: boolean = false) => {
    setLoading(!refreshing);
    setRefreshing(refreshing);
    axios.get('/lists', { ...authHeaders })
      .then((res) => {
        setLoading(false);
        setRefreshing(false);
        setLists(res.data);

        // console.log('lists', res.data);
      })
      .catch((e) => {
        setLoading(false);
        setRefreshing(false);
        setLists([]);
        console.error('lists', e);
      });
  }, [authHeaders]);

  useEffect(() => {
    fetchData(!!lists);
  }, []);

  const deleteList = useCallback((list: any) => {
    if (currentUser?.id === list.created_by) {    // is owner
      if (confirm('Bist du sicher, dass du diese Liste und all ihre Einträge löschen möchtest?')) {   // eslint-disable-line
        setLoading(true);
        axios.delete(`/lists/${list.id}`, { ...authHeaders })
          .then((res) => {
            fetchData();
            // console.log('delete list', res.data);
          })
          .catch((e) => {
            fetchData();
            console.error('delete list', e);
            alert('Da hat etwas nicht geklappt. Versuche es später erneut.');
          });
      }
    } else {    // member of list
      if (confirm('Bist du sicher, dass du aus dieser Liste austreten möchtest?')) {   // eslint-disable-line
        setLoading(true);
        axios.post(`/lists/${list.id}/removeUser`, { passcode: list.passcode }, { ...authHeaders })
          .then((res) => {
            fetchData();
            // console.log('remove user from list', res.data);
          })
          .catch((e) => {
            fetchData();
            console.error('remove user from list', e);
            alert('Da hat etwas nicht geklappt. Versuche es später erneut.');
          });
      }
    }
  }, [authHeaders, fetchData, currentUser]);

  return (
    <BoxedContentLayout>
      {
        loading &&
        <LoadingSpinner fullscreen={!!lists} />
      }
      {
        lists &&
        <>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem', marginBottom: '1.5rem' }}>
            <h3>Deine Listen</h3>
            {
              refreshing &&
              <LoadingSpinner inline size={'1.25rem'} />
            }
            <Link to='/add-list' style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', minWidth: '3rem' }}>
              <i className='material-icons'>add</i>
            </Link>
          </div>
          {
            lists.length === 0 &&
            <h4 style={{ alignSelf: 'center', margin: '2.5rem 0' }}>Keine Listen gefunden.</h4>
          }
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: '1rem' }}>
            {
              lists.map((list, index, array) => (
                <Link key={list.id} to={`/list/${list.id}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                  <ListItem
                    tileColor={Object.values(COLORS)[(array.length - 1 - index) % Object.keys(COLORS).length]}
                    tileText={list.title.substr(0, 1)}
                    action={{
                      iconName: currentUser?.id === list.created_by ? 'delete_forever' : 'delete',
                      onClick: () => deleteList(list),
                    }}
                  >
                    <h4>{ list.title }</h4>
                    <span style={{ fontSize: '.75rem', opacity: .5 }}>
                      Erstellt von { list.createdByUser?.name }{ currentUser?.id === list.created_by ? ' (mir)' : ''}
                    </span>
                  </ListItem>
                </Link>
              ))
            }
          </div>
        </>
      }
    </BoxedContentLayout>
  );
}