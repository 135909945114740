import { FC, KeyboardEventHandler, PropsWithChildren } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  & > i {
    position: absolute;
    top: 1rem;
    right: .75rem;
    margin-top: 1px;
  }
`;

const Input = styled.input`
  appearance: none;
  border: 1px solid currentColor;
  border-radius: 3px;
  font-size: 1.25rem;
  line-height: 1;
  font-family: inherit;
  color: inherit;
  padding: 1rem 1.25rem;

  &:-webkit-autofill {
    -webkit-text-fill-color: currentColor;
    -webkit-box-shadow: 0 0 0 1000px white inset;
  }

  i + & {
    padding-right: 2.75rem;
  }
`;

const Label = styled.label`
  position: absolute;
  font-size: .75rem;
  line-height: 1;
  top: -.375rem;
  left: .75rem;
  padding: 0 .5rem;
  margin-left: 1px;
  background-color: white;
`;

interface IFieldProps {
  id: string;
  value: string;
  setValue: (value: string) => void;
  type?: string;
  label?: string;
  autoComplete?: string;
  iconName?: string;
  enterKeyHint?: 'go' | 'next' | 'send';
  onEnterKeyPress?: KeyboardEventHandler<HTMLInputElement>;
}

export const InputField: FC<PropsWithChildren<IFieldProps>> = (props) => {
  const {
    id,
    value,
    setValue,
    type = 'text',
    label,
    autoComplete = 'off',
    iconName,
    children,
    enterKeyHint = 'go',
    onEnterKeyPress,
  } = props;

  return (
    <Container>
      <Label htmlFor={id}>{ label }</Label>
      {
        iconName &&
        <i className='material-icons'>{ iconName }</i>
      }
      <Input
        id={id}
        value={type === 'number' && value.length > 2 ? Number.parseFloat(value).toLocaleString(undefined, { currency: 'eur', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : value}
        onChange={({ target }) => {
          let val = target.value;
          if (type === 'number') {
            val = val.replace(/[^0-9]/g, '').replace(/^(.+)(.{2})$/g, '$1.$2');
          }
          setValue(val);
        }}
        type={type === 'number' ? 'tel' : type}
        autoComplete={autoComplete}
        enterKeyHint={enterKeyHint}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && onEnterKeyPress) {
            return onEnterKeyPress(e);
          }
        }}
      />
      { children }
    </Container>
  );
}