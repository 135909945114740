import { FC } from "react";
import styled, { keyframes } from "styled-components";

export const Form = styled.form`
  appearance: none;
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const Fieldset = styled(Form).attrs({ as: 'fieldset' })<{ disabled?: boolean }>`
  //
`;

export const Button = styled.button`
  appearance: none;
  border: 1px solid var(--color-text-default);
  border-radius: 10px;
  background-color: transparent;

  font-size: 1.125rem;
  line-height: 1;

  padding: .5rem 1rem;

  cursor: pointer;
  
  transition: color 300ms ease-out, background-color 300ms ease-out;

  @media (pointer: fine) {
    &:hover {
      color: var(--color-text-highlight);
      background-color: var(--color-text-default);
    }
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const IconButton = styled.button`
  appearance: none;
  border: 0;
  background-color: transparent;

  font-size: 1rem;
  line-height: 1;

  cursor: pointer;
  
  transition: filter 100ms ease-out;

  @media (pointer: fine) {
    &:hover {
      filter: drop-shadow(0 2px 12px rgba(0,0,0,.35));
    }
  }
`;


const KeyframeSpin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<{ size?: string }>`
  width: ${({ size = '3rem' }) => size};
  height: ${({ size = '3rem' }) => size};
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  border-right-color: var(--color-blue);
  animation: ${KeyframeSpin} 1s linear infinite;
`;

export const LoadingSpinner: FC<{ inline?: boolean, fullscreen?: boolean, size?: string }> = (props) => {
  return (
    <div style={
      !!props.fullscreen
        ? {
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
          backgroundColor: '#00000080',
          pointerEvents: 'all',
        }
        : !!props.inline
          ? { display: 'flex', alignItems: 'center', height: 0, overflow: 'visible' }
          : { display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '10rem' }
    }>
      <Spinner size={props.size} />
    </div>
  );
}