import { atom, useRecoilState, useRecoilValue } from "recoil";
import { LocalStorageEffect } from "./LocalStorageEffect";

interface ILoginState {
  authToken: string | null;
}

const loginState = atom<Partial<ILoginState>>({
  key: 'loginState',
  default: {},
  effects: [
    LocalStorageEffect('login'),
  ],
});

export const useLogin = () => useRecoilValue(loginState);
export const useLoginState = () => useRecoilState(loginState);

export const useAuthHeaders = () => {
  const state = useRecoilValue(loginState);
  if (state.authToken) {
    // return { headers: { 'Authorization': `Bearer ${state.authToken}` } };
    return { headers: { 'x-auth-token': state.authToken } };
  }
  return {};
}
