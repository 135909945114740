import axios from "axios";
import { useState } from "react";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import { Button, Fieldset, Form, LoadingSpinner } from "../elements/Form";
import { InputField } from "../elements/InputField";
import { BoxedContentLayout } from "../elements/Layout";
import { useLoginState } from "../states/LoginState";

export const Register = () => {
  const [loginState, setLoginState] = useLoginState();

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);

  if (!!loginState?.authToken) {
    return <Navigate to={'/'} replace />;
  }

  return (
    <BoxedContentLayout>
      <Form style={{ maxWidth: 450, width: '100%', alignSelf: 'center' }} onSubmit={(e) => {
        e.preventDefault();
        
        setLoading(true);
        axios.post('/register', { username, email, password })
          .then((res) => {
            setLoading(false);
            setLoginState({
              authToken: res.data.auth_token,
            });
            // console.log('register', res.data);
          })
          .catch((e) => {
            setLoading(false);
            console.error('register', e);

            if ((e?.response?.status || 500) === 403) {
              alert('Diese E-Mail-Adresse ist schon vergeben. Bitte benutze eine andere E-Mail-Adresse.');
            } else {
              alert('Da hat etwas nicht geklappt. Versuche es später erneut.');
            }
          });
      }}>
        <Fieldset disabled={loading}>
          <InputField
            id='given-name'
            value={username}
            setValue={setUsername}
            label='Username'
            autoComplete='given-name'
            iconName='person'
            enterKeyHint='next'
          />
          <InputField
            id='username'
            value={email}
            setValue={setEmail}
            type='email'
            label='E-Mail-Adresse'
            autoComplete='username'
            iconName='alternate_email'
            enterKeyHint='next'
            onEnterKeyPress={(e) => {
              e.preventDefault();
              (document.querySelector('#new-password') as HTMLInputElement)?.focus();
            }}
          />
          <InputField
            id='new-password'
            value={password}
            setValue={setPassword}
            type='password'
            label='Passwort'
            autoComplete='new-password'
            iconName='password'
            enterKeyHint='go'
          />
          <Button>registrieren</Button>
        </Fieldset>
        <Link to='/login' style={{ alignSelf: 'flex-end', color: 'blue', marginTop: '1.5rem', textAlign: 'center' }}>Du hast bereits einen Account? Dann log dich hier ein</Link>
      </Form>
      {
        loading &&
        <LoadingSpinner fullscreen />
      }
    </BoxedContentLayout>
  );
}