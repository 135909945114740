import styled from "styled-components";

export const BoxedContent = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const BoxedContentLayout = styled(BoxedContent)`
  padding: 2rem 1.5rem;
  animation: 1s appear;
`;
