import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { BoxShadow, BoxShadowHover } from "../styles/VisualEffects";
import { IconButton } from "./Form";
import { Tile } from "./Tile";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  background-color: var(--color-dark-white);
  border-radius: 10px;
  padding: 1rem;

  ${BoxShadow};

  a &, button & {
    ${BoxShadowHover};
  }
`;

const Content = styled.div`
  flex: 1;
  overflow: hidden;
  
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: .25rem;
`;

const ActionButton = styled(IconButton)`
  margin: -.75rem;
  margin-left: -.5rem;
  padding: .75rem;
`;

interface IListItemProps {
  tileColor?: { backgroundColor: string, textColor: string };
  tileText?: string;
  action?: { iconName: string, onClick: () => void };
}

export const ListItem: FC<PropsWithChildren<IListItemProps>> = (props) => {
  const {
    tileColor,
    tileText,
    action,
    children,
  } = props;

  return (
    <Wrapper>
      <Tile tileColor={tileColor}>{ tileText }</Tile>

      <Content>
        { children }
      </Content>

      {
        action &&
        <ActionButton onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          action.onClick();
        }}>
          <i className="material-icons">{ action.iconName }</i>
        </ActionButton>
      }
    </Wrapper>
  );
};
