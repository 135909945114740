import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  :root {
    /* --color-blue: #0912FF; */
    --color-blue: #3B41CC;
    --color-yellow: #FFD424;
    --color-purple: #722799;
    /* --color-green: #17FF66; */
    --color-green: #17DD44;
    --color-pink: #FF286B;
    --color-red: #FF2A24;
    --color-salmon: #FFA18D;
    --color-indigo: #7BC2CC;

    --color-light-black: #121212;
    --color-dark-white: #fafafa;

    --color-text-default: #323232;
    --color-text-highlight: #fefefe;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-size: 1rem;
    line-height: 1.41;
    font-weight: 400;
    color: var(--color-text-default);
  }

  .app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & > * {
      flex-shrink: 0;
    }

    & > main {
      flex-grow: 1;
    }

    animation: 1s appear;
  }

  h1 {
    font-size: 1.25rem;
    font-weight: 700;
  }
  h2 {
    font-size: 1.125rem;
    font-weight: 700;
  }
  h3 {
    font-size: 1.125rem;
    font-weight: 700;
  }
  h4 {
    font-size: 1.125rem;
    font-weight: 500;
  }
  h5 {
    font-size: 1rem;
    font-weight: 500;
  }
  h6 {
    font-size: .875rem;
    font-weight: 500;
  }

  a, button {
    color: inherit;
    text-decoration: none;
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
  }
`;
