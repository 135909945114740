import axios from "axios";
import { useEffect } from "react";
import { useCurrentUserState } from "../states/CurrentUserState";
import { useListsState } from "../states/ListsState";
import { useAuthHeaders, useLoginState } from "../states/LoginState";
import { Button } from "./Form";
import { BoxedContentLayout } from "./Layout";

export const Header = () => {
  const authHeaders = useAuthHeaders();
  const [loginState, setLoginState] = useLoginState();
  const [userData, setUserData] = useCurrentUserState();
  const [listsState, setListsState] = useListsState();

  useEffect(() => {
    if (!!loginState?.authToken) {
      axios.get('/getUser', { ...authHeaders })
        .then((res) => {
          setUserData(res.data);
          // console.log('getUser', res.data);
        })
        .catch((e) => {
          setUserData(null);
          // console.error('getUser', e);
        });
    } else {
      setUserData(null);
      setListsState(null);   // reset list caches
    }
  }, [loginState]);

  return (
    <header>
      <BoxedContentLayout style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '1.5rem', gap: '1rem' }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '.5rem', overflow: 'hidden' }}>
          <h1 style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Ausgaben</h1>
          {
            userData?.name &&
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '.25rem' }}>
              <i className='material-icons'>account_circle</i>
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{ userData.name }</span>
            </div>
          }
        </div>
        {
          !!loginState?.authToken &&
          <Button onClick={() => setLoginState({})}>logout</Button>
        }
      </BoxedContentLayout>
    </header>
  );
};
