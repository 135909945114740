import { atom, useRecoilState } from "recoil";
import { IUser } from "./CurrentUserState";
import { LocalStorageEffect } from "./LocalStorageEffect";

export interface IList {
  id: number;
  title: string;
  passcode: string;
  created_by: number;
  createdByUser?: IUser;
  createdAt: string;
  updatedAt: string;
}

export interface IListEntry {
  id: number;
  list_id: number;
  description: string;
  amount: number;
  paid_by: number;
  paidByUser?: IUser;
  createdAt: string;
  updatedAt: string;
}

interface IListDetails {
  list: IList | null;
  listEntries: IListEntry[];
  listMembers: IUser[];
}

interface IListsState {
  lists: IList[] | null;
  listDetails: {
    [id: number]: IListDetails | null;
  };
}

const listsState = atom<IListsState | null>({
  key: 'listsState',
  default: null,
  effects: [
    LocalStorageEffect('lists'),
  ],
});

export const useListsState = () => useRecoilState(listsState);

export const useLists = () => {
  const [state, setState] = useRecoilState(listsState);

  return [
    state?.lists || null,
    (lists: IList[] | null) => setState((state) => ({ lists, listDetails: state?.listDetails || {} }))
  ] as [IList[] | null, (list: IList[] | null) => void];
};

export const useListDetails = (id: number) => {
  const [state, setState] = useRecoilState(listsState);

  return [
    state?.listDetails[id] || null,
    (setter: ((listDetails: IListDetails) => (IListDetails | null))) => setState((state) => ({
      lists: state?.lists || null,
      listDetails: {
        ...(state?.listDetails || {}),
        [id]: setter(state?.listDetails[id] || { list: null, listEntries: [], listMembers: [] }),
      },
    })),
  ] as [IListDetails | null, (setter: ((listDetails: IListDetails) => (IListDetails | null))) => void];
};
