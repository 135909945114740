import { FC, MouseEventHandler, PropsWithChildren } from "react";
import { Link, useNavigate } from "react-router-dom";

export const BackButton: FC<PropsWithChildren<{ to?: string, onClick?: MouseEventHandler<HTMLAnchorElement> }>> = ({ to, onClick, children }) => {
  const navigate = useNavigate();

  return (
    <Link
      to={to || '/'}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }

        if (!to && !e.isDefaultPrevented()) {
          e.preventDefault();
          navigate(-1);
        }
      }}
      style={{ alignSelf: 'flex-start', display: 'flex', gap: '.5rem', margin: '-.75rem -.75rem .75rem', padding: '.75rem' }}
    >
      <i className='material-icons'>arrow_back</i>
      { children || 'zurück' }
    </Link>
  );
}