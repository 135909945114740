import { useMemo } from 'react';
import { IListEntry } from '../states/ListsState';
import { COLORS } from "../elements/Tile";

export const useColorMapping = (listEntries: IListEntry[] | null) => {
  const colorMapping: { [key: string]: typeof COLORS.blue } = useMemo(() => {
    const distinctDescriptions = (listEntries || [])
      .map(({ description }) => description?.toLowerCase()).reverse()
      .filter((item, index, array) => !!item && array.indexOf(item) === index);

    let mapping: { [key: string]: typeof COLORS.blue } = {
      rewe: COLORS.red,
      trinkgut: COLORS.inidgo,
      netto: COLORS.yellow,
      aldi: COLORS.blue,
      rossmann: COLORS.red,
      scheiwe: COLORS.salmon,
    };

    const colorKeys = Object.keys(COLORS);
    let colorIndex = 0;
    distinctDescriptions.forEach((item) => {
      if (!mapping[item]) {
        mapping[item] = (COLORS as any)[colorKeys[colorIndex++ % colorKeys.length]];
      }
    });

    return mapping;
  }, [listEntries]);

  return colorMapping;
}