import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { BackButton } from "../elements/BackButton";
import { Button, Fieldset, Form, LoadingSpinner } from "../elements/Form";
import { InputField } from "../elements/InputField";
import { BoxedContentLayout } from "../elements/Layout";
import { useAuthHeaders } from "../states/LoginState";

export const AddList = () => {
  const authHeaders = useAuthHeaders();
  const navigate = useNavigate();

  const [viewState, setViewState] = useState<'none' | 'join' | 'create'>('none');

  const [title, setTitle] = useState('');
  const [passcode, setPasscode] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle('');
    setPasscode('');
  }, [viewState]);

  const createList = useCallback(() => {
    setLoading(true);
    axios.post('/lists', { list: { title, passcode } }, { ...authHeaders })
      .then((res) => {
        setLoading(false);
        navigate(-1);
        // console.log('lists', res.data);
      })
      .catch((e) => {
        setLoading(false);
        alert('Da hat etwas nicht geklappt. Versuche es später erneut.');
        console.error('lists', e);
      });
  }, [title, passcode, authHeaders]);

  const joinList = useCallback(() => {
    setLoading(true);
    axios.post(`/lists/${title}/addUser`, { passcode }, { ...authHeaders })
      .then((res) => {
        setLoading(false);
        navigate(`/list/${res.data.id}`, { replace: true });
        // console.log('lists addUser', res.data);
      })
      .catch((e) => {
        setLoading(false);
        alert('Da hat etwas nicht geklappt. Versuche es später erneut.');
        console.error('lists addUser', e);
      });
  }, [title, passcode, authHeaders]);

  return (
    <BoxedContentLayout>
      <BackButton
        onClick={(e) => {
          if (viewState !== 'none') {
            setViewState('none');
            e.preventDefault();
          }
        }}
      >zurück</BackButton>
      {
        viewState === 'none' &&
        <>
          <Button type='button' style={{ alignSelf: 'center', marginBottom: '1rem' }} onClick={() => setViewState('join')}>beitreten</Button>
          <Button type='button' style={{ alignSelf: 'center' }} onClick={() => setViewState('create')}>neu erstellen</Button>
        </>
      }
      {
        viewState !== 'none' &&
        <Form style={{ maxWidth: 450, width: '100%', alignSelf: 'center', animation: '.5s appear' }} onSubmit={(e) => {
          e.preventDefault();
          
          switch (viewState) {
            case 'join':
              joinList();
              break;
            case 'create':
              createList();
              break;
          }
        }}>
          <h4 style={{ marginBottom: '1.75rem' }}>{ viewState === 'create' ? 'Liste erstellen' : 'Liste beitreten' }</h4>
          <Fieldset disabled={loading}>
            {
              viewState === 'create' &&
              <InputField
                id='list-title'
                value={title}
                setValue={setTitle}
                label='Name'
                iconName='abc'
                enterKeyHint='next'
                onEnterKeyPress={(e) => {
                  e.preventDefault();
                  (document.querySelector('#list-passcode') as HTMLInputElement)?.focus();
                }}
              />
            }
            {
              viewState === 'join' &&
              <InputField
                id='list-id'
                value={title}
                setValue={setTitle}
                label='Listen ID'
                iconName='list_alt'
                enterKeyHint='next'
                onEnterKeyPress={(e) => {
                  e.preventDefault();
                  (document.querySelector('#list-passcode') as HTMLInputElement)?.focus();
                }}
              />
            }
            <InputField
              id='list-passcode'
              value={passcode}
              setValue={setPasscode}
              label='Passcode'
              iconName='key'
              enterKeyHint='go'
            />
            <Button>{ viewState === 'create' ? 'erstellen' : 'beitreten' }</Button>
          </Fieldset>
        </Form>
      }
      {
        loading &&
        <LoadingSpinner fullscreen />
      }
    </BoxedContentLayout>
  );
}