import React from 'react';
import { BrowserRouter, Outlet, Route, Routes, Navigate } from 'react-router-dom';
import { Header } from './elements/Header';
import { AddList } from './screens/AddList';
import { AddListEntry } from './screens/AddListEntry';
import { ListsDetail } from './screens/ListsDetail';
import { ListsOverview } from './screens/ListsOverview';
import { Login } from './screens/Login';
import { Register } from './screens/Register';
import { useLogin } from './states/LoginState';

function App() {
  const loginState = useLogin();

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={(
          <div className="app">
            <Header />

            <main>
              <Outlet />
            </main>

            <footer>
              
            </footer>
          </div>
        )}>
          {
            !!loginState?.authToken ? (
              <>
                <Route path='/' element={<ListsOverview />} />
                <Route path='/add-list' element={<AddList />} />
                <Route path='/list/:listId' element={<ListsDetail />} />
                <Route path='/list/:listId/add-entry' element={<AddListEntry />} />
              </>
            ) : (
              <Route path='/' element={<Navigate to={'/login'} replace />} />
            )
          }
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='*' element={<Navigate to={'/login'} replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
