import axios from "axios";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import styled, { keyframes } from "styled-components";
import { BackButton } from "../elements/BackButton";
import { Button, Fieldset, Form, LoadingSpinner } from "../elements/Form";
import { InputField } from "../elements/InputField";
import { BoxedContentLayout } from "../elements/Layout";
import { COLORS, WideTile } from "../elements/Tile";
import { useColorMapping } from "../hooks/useColorMapping";
import { useListDetails } from "../states/ListsState";
import { useAuthHeaders } from "../states/LoginState";

const AnimAppear = keyframes`
  from {
    opacity: 0;
  }
`;

const SuggestionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  overflow: auto;
  margin: -1.5rem -1.5rem 1rem;
  padding: 1rem 1.5rem;

  // transition
  animation: ${AnimAppear} .5s ease-out;
`;

const SuggestionTile = styled.button.attrs({ type: 'button' })`
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
  display: contents;

  & > * {
    border: 0px solid var(--color-light-black);
    transition: border-width 100ms ease-out;
  }

  &:disabled > * {
    border-width: 2px;
  }
`;

export const AddListEntry = () => {
  const { listId } = useParams();
  const authHeaders = useAuthHeaders();
  const navigate = useNavigate();

  const [listDetails] = useListDetails(+(listId || '0'));
  const { listEntries = null } = listDetails || {};
  const colorMapping = useColorMapping(listEntries);

  const lastDescriptions = useMemo(() => {
    return (listEntries || []).map(({ description }) => description).filter((item, index, array) => array.indexOf(item) === index).filter(Boolean);
  }, [listEntries]);

  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');

  const [loading, setLoading] = useState(false);

  const suggestions = useMemo(() => {
    return lastDescriptions.filter((item) => item.startsWith(description));
  }, [lastDescriptions, description]);

  return (
    <BoxedContentLayout>
      <BackButton />
      <Form style={{ maxWidth: 450, width: '100%', alignSelf: 'center', animation: '.5s appear' }} onSubmit={(e) => {
        e.preventDefault();
        
        setLoading(true);
        axios.post(`/lists/${listId}/entries`, { entry: { description, amount } }, { ...authHeaders })
          .then((res) => {
            setLoading(false);
            navigate(-1);
            // console.log('list entry', res.data);
          })
          .catch((e) => {
            setLoading(false);
            alert('Da hat etwas nicht geklappt. Versuche es später erneut.');
            console.error('list entry', e);
          });
      }}>
        <h4 style={{ marginBottom: '1.75rem' }}>Ausgabe hinzufügen</h4>
        {
          suggestions.length > 0 &&
          <SuggestionsWrapper>
            {
              suggestions.map((suggestion, index, array) => (
                <SuggestionTile
                  key={suggestion}
                  disabled={description === suggestion}
                  onClick={() => {
                    setDescription(suggestion);
                    (document.querySelector('#entry-amount') as HTMLInputElement)?.focus();   // focus amount input
                  }}
                >
                  <WideTile
                    tileColor={colorMapping[suggestion.toLowerCase()] || Object.values(COLORS)[(array.length - 1 - index) % Object.keys(COLORS).length]}
                  >
                    <h5 style={{ textAlign: 'center' }}>{suggestion}</h5>
                  </WideTile>
                </SuggestionTile>
              ))
            }
          </SuggestionsWrapper>
        }
        <Fieldset disabled={loading}>
          <InputField
            id='entry-description'
            value={description}
            setValue={setDescription}
            label='Beschreibung'
            iconName='description'
            enterKeyHint='next'
            onEnterKeyPress={(e) => {
              e.preventDefault();
              (document.querySelector('#entry-amount') as HTMLInputElement)?.focus();
            }}
          />
          <InputField
            id='entry-amount'
            value={amount}
            setValue={setAmount}
            label='Betrag'
            type='number'
            iconName='euro'
            enterKeyHint='go'
          />
          <Button>hinzufügen</Button>
        </Fieldset>
      </Form>
      {
        loading &&
        <LoadingSpinner fullscreen />
      }
    </BoxedContentLayout>
  );
}