import { atom, useRecoilState, useRecoilValue } from "recoil";
import { LocalStorageEffect } from "./LocalStorageEffect";

export interface IUser {
  id: number;
  name: string;
  imageUrl: string | null;
}

const userState = atom<Partial<IUser> | null>({
  key: 'userState',
  default: null,
  effects: [
    LocalStorageEffect('user'),
  ],
});

export const useCurrentUser = () => useRecoilValue(userState);
export const useCurrentUserState = () => useRecoilState(userState);
