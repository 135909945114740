import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BackButton } from "../elements/BackButton";
import { Button, IconButton, LoadingSpinner } from "../elements/Form";
import { BoxedContentLayout } from "../elements/Layout";
import { ListItem } from "../elements/ListItem";
import { COLORS, LargeTile, LargeTileCurrencyTitle } from "../elements/Tile";
import { useColorMapping } from "../hooks/useColorMapping";
import { IUser, useCurrentUser } from "../states/CurrentUserState";
import { IList, IListEntry, useListDetails } from "../states/ListsState";
import { useAuthHeaders } from "../states/LoginState";

export const ListsDetail = () => {
  const { listId } = useParams();
  const authHeaders = useAuthHeaders();
  const currentUser = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [listDetails, setListDetails] = useListDetails(+(listId || '0'));
  const { list = null, listEntries = null, listMembers = null } = listDetails || {};

  const [entryInEditMode, setEntryInEditMode] = useState<any | null>(null);

  const fetchData = useCallback((refreshing: boolean = false) => {
    setLoading(!refreshing);
    setRefreshing(refreshing);
    Promise.all([
      // GET list by id
      axios.get(`/lists/${listId}`, { ...authHeaders })
        .then((res) => {
          setListDetails((state) => ({ ...state, list: res.data as IList }));
          // console.log('list', res.data);
        })
        .catch((e) => {
          setListDetails((state) => ({ ...state, list: null }));
          console.error('list', e);
        }),
      // GET list entries
      axios.get(`/lists/${listId}/entries`, { ...authHeaders })
        .then((res) => {
          setListDetails((state) => ({ ...state, listEntries: res.data as IListEntry[] }));
          // console.log('list entries', res.data);
        })
        .catch((e) => {
          setListDetails((state) => ({ ...state, listEntries: [] }));
          console.error('list entries', e);
        }),
      // GET list members
      axios.get(`/lists/${listId}/members`, { ...authHeaders })
        .then((res) => {
          setListDetails((state) => ({ ...state, listMembers: res.data as IUser[] }));
          // console.log('list members', res.data);
        })
        .catch((e) => {
          setListDetails((state) => ({ ...state, listMembers: [] }));
          console.error('list members', e);
        }),
    ])
      .then(() => {
        setLoading(false);
        setRefreshing(false);
      })
      .catch(() => {
        setLoading(false);
        setRefreshing(false);
      });
  }, [listId, authHeaders]);

  useEffect(() => {
    fetchData(list !== null && listEntries !== null);
  }, []);

  const deleteEntry = useCallback((id: number) => {
    if (confirm('Bist du sicher, dass du diese Ausgabe löschen möchtest?')) {   // eslint-disable-line
      setLoading(true);
      axios.delete(`/lists/${listId}/entries/${id}`, { ...authHeaders })
        .then((res) => {
          fetchData();
          // console.log('delete entry', res.data);
        })
        .catch((e) => {
          fetchData();
          console.error('delete entry', e);
          alert('Da hat etwas nicht geklappt. Versuche es später erneut.');
        });
    }
  }, [listId, authHeaders, fetchData]);

  const editEntry = useCallback((entry: IListEntry, updatePayload: { entry: Partial<IListEntry> }) => {
    // if (confirm('Are you sure you want to delete this entry?')) {   // eslint-disable-line
      setLoading(true);
      axios.put(`/lists/${listId}/entries/${entry.id}`, updatePayload, { ...authHeaders })
        .then((res) => {
          fetchData();
          // console.log('edit entry', res.data);
        })
        .catch((e) => {
          fetchData();
          console.error('edit entry', e);
          alert('Da hat etwas nicht geklappt. Versuche es später erneut.');
        });
    // }
  }, [listId, authHeaders, fetchData]);

  const amountTotal: number = useMemo(() => (listEntries || []).reduce((res, item) => res + item.amount, 0), [listEntries]);
  const amountPerUser: number = useMemo(() => amountTotal / (1 + (listMembers || []).length), [listMembers, amountTotal]);
  const userAmounts: { [userId: number]: number } = useMemo(() => (listEntries || []).reduce((res, item) => {
    return {
      ...res,
      [item.paid_by]: (res[item.paid_by] || 0) + item.amount,
    }
  }, {} as { [userId: number]: number }), [listEntries]);

  const colorMapping = useColorMapping(listEntries);

  return (
    <BoxedContentLayout>
      <BackButton />
      {
        loading &&
        <LoadingSpinner fullscreen={list !== null && listEntries !== null} />
      }
      {
        (list === null || listEntries === null) && !loading &&
        <>
          <h4 style={{ alignSelf: 'center', marginBottom: '1.5rem' }}>Liste konnte nicht geladen werden.</h4>
          <Button style={{ alignSelf: 'center' }} onClick={() => fetchData()}>nochmal versuchen</Button>
        </>
      }
      {
        list !== null && listEntries !== null &&
        <>
          <LargeTile
            tileColor={{
              backgroundColor: 'var(--color-light-black)',
              textColor: 'var(--color-text-highlight)',
            }}
            style={{ width: 'auto', height: 'auto', alignItems: 'stretch', justifyContent: 'flex-start', padding: '1.5rem', gap: '2rem', marginBottom: '1.5rem' }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
              <h2 style={{
                fontSize: list.title.split(' ').reduce((res: number, item: string) => Math.max(res, item.length), 0) > 8
                  ? '2rem'
                  : '2.5rem',
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>{ list.title }</h2>
              <h3 style={{ padding: '.75rem 0', marginLeft: 'auto' }}>ID: { list.id }</h3>
            </div>
            <h4 style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>Passcode: { list.passcode }</h4>
          </LargeTile>

          {
            listEntries.length > 0 &&
            <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', overflow: 'auto', margin: '-1.5rem -1.5rem 1rem', padding: '1rem 1.5rem' }}>
              <LargeTile tileColor={COLORS.blue}>
                <LargeTileCurrencyTitle>{ amountTotal }</LargeTileCurrencyTitle>
                <h5>Gesamt</h5>
              </LargeTile>
              {
                currentUser?.id &&
                <LargeTile
                  tileColor={{
                    backgroundColor: ((userAmounts[currentUser.id] || 0) - amountPerUser) > 0 ? 'var(--color-green)' : 'var(--color-red)',
                    textColor: 'var(--color-text-highlight)',
                  }}
                >
                  <LargeTileCurrencyTitle>
                    { (userAmounts[currentUser.id] || 0) - amountPerUser }
                  </LargeTileCurrencyTitle>
                  <h5>Mein Guthaben</h5>
                </LargeTile>
              }
              {
                ([list.createdByUser, ...(listMembers || [])] as IUser[]).filter(({ id }) => id !== currentUser?.id).map((user) => {
                  return (
                    <LargeTile
                      tileColor={{
                        backgroundColor: ((userAmounts[user.id] || 0) - amountPerUser) > 0 ? 'var(--color-green)' : 'var(--color-red)',
                        textColor: 'var(--color-text-highlight)',
                      }}
                    >
                      <LargeTileCurrencyTitle>
                        { (userAmounts[user.id] || 0) - amountPerUser }
                      </LargeTileCurrencyTitle>
                      <h5 style={{ textAlign: 'center' }}>Guthaben { user.name }</h5>
                    </LargeTile>
                  );
                })
              }
            </div>
          }

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem', marginBottom: '1.5rem' }}>
            <h3>Letzte Ausgaben</h3>
            {
              refreshing &&
              <LoadingSpinner inline size={'1.25rem'} />
            }
            <Link to={`/list/${listId}/add-entry`} style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', minWidth: '3rem' }}>
              <i className='material-icons'>add</i>
            </Link>
          </div>
          {
            listEntries.length === 0 &&
            <h4 style={{ alignSelf: 'center', margin: '2.5rem 0' }}>Keine Einträge.</h4>
          }
          {
            listEntries.length > 0 &&
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: '1rem' }}>
              {
                (listEntries as IListEntry[]).map((listEntry, index, array) => (
                  <ListItem
                    key={listEntry.id}
                    tileColor={listEntry.description && colorMapping[listEntry.description.toLowerCase()] || Object.values(COLORS)[(array.length - 1 - index) % Object.keys(COLORS).length]}
                    tileText={listEntry.description.substr(0, 1)}
                    action={{
                      iconName: 'delete',
                      onClick: () => deleteEntry(listEntry.id),
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem' }}>
                      <h4 style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{ listEntry.description }</h4>
                      <h6 style={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}>{ (listEntry.amount as number).toLocaleString(undefined, { currency: 'eur', minimumFractionDigits: 2, maximumFractionDigits: 2 }) } €</h6>
                    </div>
                    <IconButton
                      style={{ fontSize: '.75rem', opacity: .5, alignSelf: 'flex-start', margin: '.25rem 0 -.125rem', textAlign: 'left' }}
                      type='button'
                      onClick={() => setEntryInEditMode(listEntry)}
                    >
                      Bezahlt von { listEntry.paidByUser?.name }{ currentUser?.id === listEntry.paid_by ? ' (mir)' : ''}
                    </IconButton>
                    <span style={{ fontSize: '.75rem', opacity: .5 }}>{ new Date(listEntry.createdAt).toLocaleDateString(undefined, { dateStyle: 'short' }) }</span>
                  </ListItem>
                ))
              }
            </div>
          }
        </>
      }
      {
        entryInEditMode &&
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#00000080', padding: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }} onClick={() => setEntryInEditMode(null)}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', margin: 'auto', backgroundColor: 'white', padding: '2rem 1.5rem', gap: '1.5rem', minWidth: 250, pointerEvents: 'all' }}>
            <h4 style={{ margin: '0 auto' }}>Wer hat hierfür bezahlt?</h4>
            {
              list?.createdByUser &&
              <IconButton
                type='button'
                onClick={() => editEntry(entryInEditMode, { entry: { paid_by: list.createdByUser!.id } })}
                style={{
                  margin: '0 auto',
                  color: entryInEditMode?.paid_by === list.createdByUser.id ? 'var(--color-blue)' : 'var(--color-text-default)',
                  opacity: entryInEditMode?.paid_by === list.createdByUser.id ? 1 : .5,
                }}
              >
                { list.createdByUser.name }{ currentUser?.id === list.createdByUser.id ? ' (Ich)' : ''}
              </IconButton>
            }
            {
              (listMembers || []).map((member) => (
                <IconButton
                  type='button'
                  onClick={() => editEntry(entryInEditMode, { entry: { paid_by: member.id } })}
                  style={{
                    margin: '0 auto',
                    color: entryInEditMode?.paid_by === member.id ? 'var(--color-blue)' : 'var(--color-text-default)',
                    opacity: entryInEditMode?.paid_by === member.id ? 1 : .5,
                  }}
                >
                  { member.name }{ currentUser?.id === member.id ? ' (Ich)' : ''}
                </IconButton>
              ))
            }
          </div>
        </div>
      }
    </BoxedContentLayout>
  );
}