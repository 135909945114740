import { FC } from 'react';
import styled from 'styled-components';
import { BoxShadowStrong } from '../styles/VisualEffects';

export const COLORS = {
  blue: {
    backgroundColor: 'var(--color-blue)',
    textColor: 'var(--color-text-highlight)',
  },
  yellow: {
    backgroundColor: 'var(--color-yellow)',
    textColor: 'var(--color-text-default)',
  },
  purple: {
    backgroundColor: 'var(--color-purple)',
    textColor: 'var(--color-text-highlight)',
  },
  green: {
    backgroundColor: 'var(--color-green)',
    textColor: 'var(--color-text-default)',
  },
  pink: {
    backgroundColor: 'var(--color-pink)',
    textColor: 'var(--color-text-highlight)',
  },
  red: {
    backgroundColor: 'var(--color-red)',
    textColor: 'var(--color-text-highlight)',
  },
  salmon: {
    backgroundColor: 'var(--color-salmon)',
    textColor: 'var(--color-text-highlight)',
  },
  inidgo: {
    backgroundColor: 'var(--color-indigo)',
    textColor: 'var(--color-text-highlight)',
  },
};

export const Tile = styled.div<{ tileColor?: { backgroundColor: string; textColor: string } }>`
  --size: 3rem;
  width: var(--size);
  height: var(--size);
  border-radius: 10px;

  background-color: ${({ tileColor }) => tileColor?.backgroundColor || 'var(--color-yellow)'};
  color: ${({ tileColor }) => tileColor?.textColor || 'var(--color-text-default)'};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: .5rem;
`;

export const LargeTile = styled(Tile)`
  --size: 10rem;
  border-radius: 1.125rem;
  gap: .75rem;

  ${BoxShadowStrong};
`;

export const WideTile = styled(Tile)`
  width: auto;
  border-radius: 1.125rem;
  gap: .75rem;

  flex-direction: row;
  padding: 0 1rem;

  ${BoxShadowStrong};
`;

export const LargeTileCurrencyTitle: FC<{ children: number }> = ({ children }) => {
  const currencyTitle = children.toLocaleString(undefined, { currency: 'eur', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const fontSize = (() => {
    if (children >= 100000) {
      return '1.375rem';
    }
    if (children >= 10000) {
      return '1.5rem';
    }
    return '1.625rem';
  })();

  return (
    <h2 style={{ fontSize }}>{ currencyTitle } €</h2>
  );
}