import { css } from "styled-components";

export const BoxShadow = css`
  box-shadow: 0 2px 12px rgba(0,0,0,.1);
`;

export const BoxShadowHover = css`
  @media (pointer: fine) {
    &:hover {
      box-shadow: 1px 3px 12px rgba(0,0,0,.15);
    }
  }
`;

export const BoxShadowStrong = css`
  box-shadow: 0 2px 12px rgba(0,0,0,.25);
`;